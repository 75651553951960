
const firebaseConfig = {

    apiKey: "AIzaSyBQIto0hKEFYUaxs9i2HrHB-rfWVJjBBnM",

    authDomain: "macp-a100d.firebaseapp.com",

    databaseURL: "https://macp-a100d-default-rtdb.asia-southeast1.firebasedatabase.app",

    projectId: "macp-a100d",

    storageBucket: "macp-a100d.appspot.com",

    messagingSenderId: "444638887438",

    appId: "1:444638887438:web:d44ca7a1d9e184d28f465f",

    measurementId: "G-L1RCPPGXST"

  };


export default firebaseConfig; 